

export function readFile(path = "",type = "text"):Promise<any> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET",path,true);
        xhr.send(null);
        // eslint-disable-next-line
        // @ts-ignore
        xhr.responseType = type;
        xhr.onreadystatechange = function () {
            if ( xhr.readyState == 4 && xhr.status == 200 ){
                resolve(xhr.responseText)
            }
        }
    })
}
