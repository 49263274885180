import {readFile} from "@/Api/ReadFile";
import showdown from "showdown"

export function getMarkDown() {
    return Promise.resolve(readFile("Agreement.md").then(res=>{
        const converter = new showdown.Converter();
        let html = converter.makeHtml(res)
        return html;
    }))
}
